<template>
  <div class="app-container">
    <div class="wrap">
      <navBar :title="'出差申请'" @handleGoBack="handleGoBack"></navBar>
      <div class="add-form">
        <div class="line"></div>
        <van-form @submit="onSubmit">
          <van-field
              v-model="formInline.deptName"
              label="申请部门"
              placeholder="请输入"
              disabled
          />

          <van-field
              v-model="formInline.name"
              label="申请人"
              name="contactWay"
              placeholder="请输入"
              disabled
          />

          <van-field
              v-model="formInline.time"
              label="申请时间"
              placeholder="请输入"
              disabled
          />

          <van-field
              readonly
              clickable
              name="picker"
              v-model="formInline.flowName"
              label="审批流程"
              placeholder="请选择"
              @click="showFlowPicker = true"
              :rules="[{ required: true, message: '请选择审批流程' }]"
          />
          <van-popup v-model="showFlowPicker" position="bottom">
            <van-picker
                show-toolbar
                :columns="flowList"
                value-key="flowTitle"
                @confirm="onConfirmFlow"
                @cancel="showFlowPicker = false"
            />
          </van-popup>

          <van-field
              v-model="formInline.duration"
              label="申请时长(时)"
              type="number"
              placeholder="请输入"
          />

          <van-field
              readonly
              clickable
              name="calendar"
              :value="formInline.starttime"
              label="开始时间"
              placeholder="点击选择日期"
              @click="showDataTimePop = true"
          />

          <van-popup v-model="showDataTimePop" style="width: 70%">
            <van-datetime-picker
                v-model="formInline.beginTime"
                type="datetime"
                title="请选择开始时间"
                @confirm="onConfirmTime"
            />
          </van-popup>

          <van-field
              readonly
              clickable
              name="calendar"
              :value="formInline.endtime"
              label="结束时间"
              placeholder="点击选择日期"
              @click="showEndTimePop = true"
          />

          <van-popup v-model="showEndTimePop" style="width: 70%">
            <van-datetime-picker
                v-model="formInline.endTime"
                type="datetime"
                title="请选择结束时间"
                @confirm="onConfirmEndTime"
            />
          </van-popup>

          <textAreaInput
              :title="'出差原因'"
              :remark="formInline.content"
              @change="handleContentChange"
          />

          <div class="line"></div>
          <van-field name="uploader" label="附件" v-if="isShowAuth">
            <template #input>
              <van-uploader v-model="fileList" multiple :max-count="2">
                <div class="flex">
                  <i class="iconfont icon-fujian1 mrg-r-10" style="font-size: 20px"></i>
                  <span>点击上传</span>
                </div>
              </van-uploader>
            </template>
          </van-field>
          <van-button class="btn" block type="info" native-type="submit">提交</van-button>
        </van-form>
      </div>
    </div>
  </div>
</template>
<script>
import {changeToCurrentDay, getCurrentMonthDay} from '@/utils/ruoyi';
import {submiteUpload} from '@/api/system/config';
import {addApprove} from "@/api/approve/approve";
import {findFlowByUserId} from "@/api/approve/approveFlow";

export default {
  data() {
    return {
      formInline: {
        deptName: localStorage.getItem('deptName'),
        name: localStorage.getItem('realname'),
        time: getCurrentMonthDay(),
        beginTime: new Date(),
        endTime: new Date()
      },
      showEndTimePop: false,
      showDataTimePop: false,
      showFlowPicker: false,
      fileList: [],
      flowList: [],
      userId: localStorage.getItem('userId'),
      typeName: '出差申请',
      isShowAuth: localStorage.getItem('isShowAuth') === '1'
    };
  },

  created() {
    //查询流程
    this.findFlowList()
  },

  methods: {
    handleGoBack() {
      this.$router.replace('/approve/myApply');
      this.$router.go(-1)
    },

    //查询流程
    findFlowList() {
      findFlowByUserId({typeName: this.typeName, userId: this.userId}).then(res => {
        this.flowList = res.data
        if (this.flowList && this.flowList.length == 1) {
          this.formInline.flowId = this.flowList[0].id
          this.formInline.flowName = this.flowList[0].flowTitle
        }
      })
    },

    onConfirmTime(value) {
      const time = changeToCurrentDay(value);
      this.formInline.starttime = time;
      this.showDataTimePop = false;
    },

    onConfirmEndTime(value) {
      const time = changeToCurrentDay(value);
      this.formInline.endtime = time;
      this.showEndTimePop = false;
    },

    //审批流程
    onConfirmFlow(value) {
      this.formInline.flowName = value.flowTitle;
      this.formInline.flowId = value.id;
      this.showFlowPicker = false;
    },

    async onSubmit() {
      let form = {}
      form.approveTitle = this.formInline.name + '-出差申请'
      form.remark = JSON.stringify(this.formInline)
      form.route = '/approve/waitApprove'
      form.typeStr = this.typeName
      form.flowId = this.formInline.flowId
      let result = await addApprove(form)
      let businessId = result.data;


      if (result.code == 200) {
        if (this.fileList) {
          this.fileList.forEach(item => {
            let params = new FormData();
            params.append('file', item.file);
            params.append('businessType', 'sys_approve');
            params.append('businessId', businessId);
            submiteUpload(params);
          })
          this.fileList = []
        }
        this.$toast.success('添加成功');
        this.handleGoBack();
      }
    },

    onFailed(errorInfo) {
      console.log('failed', errorInfo);
    },

    handleContentChange(val) {
      this.formInline.remark = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  width: 710px;
  height: 100px;
  background: #0091ff;
  border-radius: 6px 6px 6px 6px;
  margin: 118px auto 0;
  font-size: 38px;
}

// 导航样式 --start
::v-deep .van-hairline--bottom::after {
  border-bottom: none;
}

::v-deep .van-nav-bar .van-icon {
  color: #000;
}

::v-deep .van-nav-bar__content {
  height: 100px;
  line-height: 100px;
}

::v-deep .van-nav-bar__arrow,
::v-deep .van-nav-bar .van-icon {
  font-size: 32px;
}

// 导航样式 --end

::v-deep .van-radio__label {
  line-height: 100px;
}

::v-deep .van-radio__icon .van-icon {
  width: none;
  height: none;
  line-height: none;
}

::v-deep .van-radio__icon {
  line-height: 0;
}

::v-deep .van-picker__toolbar {
  height: 80px;
}

::v-deep .van-picker__title {
  line-height: 80px;
  font-size: 26px;
}
</style>
